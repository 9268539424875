<template>
  <div class="mt-4 py-2" style="background-color: #f7f7f7">
    <div class="container mt-5">
      <!-- <div class="row" style="max-height: 400px">
        <div class="col-12 m-0 p-0">
          <img src="@/assets/Home.svg" alt="intro" style="width: 100%;" />
        </div>
      </div>-->
      <b-modal
        id="gp-modal"
        v-model="modalGP"
        centered
        title="Requirements for virtual care"
        :hide-footer="true"
      >
        <template>
          <ul>
            <li>
              Patient must meet the following requirements for virtual care
            </li>
            <li>
              Patient does not have sudden weakness or numbness in the face, arm
              or leg (especially on one side of the body)
            </li>
            <li>
              Patient does not have sudden confusion or trouble speaking or
              understanding speech
            </li>
            <li>
              Patient does not have sudden vision problems in one or both eyes
            </li>
            <li>
              Patient does not have sudden difficulty walking or dizziness, loss
              of balance or problems with coordination
            </li>
            <li>Patient does not have severe headache with no known cause</li>
            <li>Patient does not have chest pain</li>
            <li>Patient is not actively bleeding from any part of the body</li>
            <li>
              has not had a seizure within the last 24 hours, or has had but is
              known to have seizure disorder
            </li>
            <li>
              Patient is not a child with persistent fever (Temperature >38.5
              °C)
            </li>
          </ul>

          <div class="row m-0 justify-content-end">
            <button class="btn btn-sm btn-outline-danger mx-2" @click="close">
              Not eligible
            </button>
            <button class="btn btn-sm btn-outline-success" @click="eligible">
              Eligible
            </button>
          </div>
        </template>
      </b-modal>
      <b-modal
        id="specialist-modal"
        v-model="modalSpecial"
        size="lg"
        centered
        title="Available specialist doctors"
        :hide-footer="true"
      >
        <template>
          <div class="has-search">
            <i class="fa fa-search form-control-feedback"></i>

            <input
              type="search"
              class="search-control"
              v-model="search"
              placeholder="Search for a need"
            />
          </div>
          <div class="row m-0 justify-content-center align-items-center">
            <div
              class="col-12 col-md-6 px-1"
              v-for="(list, index) in specialList"
              :key="index"
            >
              <button
                @click="openSpecialist(list)"
                class="btn btn-outline-light shadow-sm mb-2"
                style="border: 0px; border-radius: 7px; width: 100%"
              >
                <div class="row m-0 align-items-center">
                  <div class="col-3 p-1">
                    <img
                      :src="image"
                      alt
                      style="width: 40px; height: 40px; border-radius: 50%"
                    />
                  </div>
                  <div class="col-9 pl-2 p-0 text-left text-dark">
                    <h6 class="font-weight-bold m-0">
                      {{
                        list.sz_fname +
                        `${list.sz_oname ? " " + list.sz_oname + " " : " "}` +
                        list.sz_lname
                      }}
                    </h6>
                    <small style="font-size: 11px" class="text-muted">{{
                      list.specialty
                    }}</small>
                    <div
                      style="font-size: 14px"
                      class="row m-0 align-items-center"
                      v-for="(days, ind) in list.employee_availability"
                      :key="ind"
                    >
                      <b-badge
                        pill
                        variant="light"
                        class="text-info pills mr-1"
                        v-for="(
                          day, indx
                        ) in days.employee_availability_days_of_week"
                        :key="indx"
                        >{{ day.day }}</b-badge
                      >
                      <!-- <div>
                        <span class="pr-2 mr-1">
                          <i class="fa fa-comments text-info"></i>
                        </span>
                        <span>
                          <i class="fa fa-video text-danger"></i>
                        </span>
                      </div> -->
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </div>
          <button
            @click="openSpecialist('talk')"
            class="btn btn-outline-light shadow-sm my-2"
            style="border: 0px; border-radius: 7px; width: 100%"
          >
            <div class="text-center py-2">
              <h5
                class="font-weight-bold text-dark m-0"
                v-if="specialList.length != 0"
              >
                Not sure? Talk to someone ...
              </h5>
              <h5 class="font-weight-bold text-dark m-0" v-else>
                Please, talk to someone ...
              </h5>
            </div>
          </button>
        </template>
      </b-modal>
      <b-modal
        id="corona-modal"
        v-model="modalCorona"
        centered
        title="Select Covid 19 service"
        :hide-footer="true"
      >
        <template>
          <div class="row m-0 mt-3 align-items-center">
            <div
              class="col-12 px-1"
              v-for="(list, index) in CoronaTiers.health_service_price_tiers"
              :key="index"
            >
              <button
                @click="openCorona(list)"
                class="btn btn-outline-light shadow-sm mb-3"
                style="border: 0px; border-radius: 7px; width: 100%"
              >
                <div class="row m-0 align-items-center">
                  <div class="col-11 pl-2 p-0 text-left text-dark">
                    <h6 class="text-capitalize font-weight-bold m-0">
                      {{ list.display }}
                    </h6>
                    <div>
                      <small style="font-size: 10px" class="text-muted">{{
                        list.description
                      }}</small>
                    </div>
                    <small style="font-size: 11px; font-weight: 900"
                      >{{ list.charge }} {{ list.currency }}</small
                    >
                  </div>
                </div>
              </button>
            </div>
          </div>
        </template>
      </b-modal>
      <div class="row text-left px-2">
        <div class="col">
          <div v-if="loggedIn">
            <span class="px-1" style="font-size: 1rem">
              <!--{{ getGreeting }}-->
            </span>
            <!-- <br /> -->
            <b
              class="m-0"
              style="
                font-size: 1.5rem;
                text-overflow: ellipsis;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                display: block;
              "
              >{{ getTimeOfDay }}, {{ userInfo.szfirstname || "Dear" }}!</b
            >
          </div>
          <h6 class="greeting text-muted mb-4" style="font-size: 0.9rem">
            <!-- I am here to help you feel healthier and happier.  -->
            How may I help you today?
          </h6>
          <!-- <div>
            <span
              class="radio--select m-1"
              v-for="(option, index) in options"
              :key="index"
            >
              <input
                type="radio"
                name="exampleRadios"
                v-model="selected"
                :id="option.value + index"
                :value="option.value"
              />
              <label
                class="form-check-label mb-2 font-weight-700 font-size-14"
                :for="option.value + index"
                >{{ option.text }}</label
              >
            </span>
          </div> -->
        </div>
      </div>
      <div
        v-if="
          userSessionData.upcomingAppointments.length == 0 &&
          userSessionData.unconfirmedAppointments.length > 0
        "
        class="row m-0 my-3"
      >
        <div class="report-card col-12 p-0 my-3">
          <img src="@/assets/logo.svg" class="ribbon" />
          <div class="card shadow-sm" style="border: 0px; border-radius: 24px">
            <div class="text-left p-3">
              <b-badge
                pill
                variant="light"
                class="my-2 py-1 text-info pills"
                style="
                  font-size: 10px;
                  font-family: sans-serif;
                  letter-spacing: 2px;
                "
                >YOUR STATUS</b-badge
              >
              <h4 class="text-dark m-0">
                You have
                {{ userSessionData.unconfirmedAppointments.length }} unconfirmed
                {{
                  userSessionData.upcomingAppointments.length == 1
                    ? "appointment"
                    : "appointments"
                }}
              </h4>
              <small class="text-muted"
                >you need to pay for this appointment</small
              >
              <br />
              <small class="text-muted">before it is confirmed</small>
              <div class="mt-2">
                <b-badge
                  pill
                  variant="light"
                  :to="url"
                  class="my-2 p-3 text-info pills"
                  style="
                    font-family: sans-serif;
                    font-size: 15px;
                    line-height: 19px;
                  "
                  >Click here to pay for the appointment</b-badge
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="
          userSessionData.upcomingAppointments.length == 0 &&
          userSessionData.unconfirmedAppointments.length == 0
        "
        class="row m-0 mt-3"
      >
        <div class="p-2 col-12 py-3">
          <div class="card shadow" style="border: 0px; border-radius: 24px">
            <div class="text-left p-3">
              <div
                v-if="loading && loggedIn"
                style="
                  background-color: white;
                  position: absolute;
                  z-index: 1029;
                  top: 6px;
                  left: 6px;
                  bottom: 6px;
                  right: 6px;
                "
              >
                <!--<b-spinner
                  variant="info"
                  style="width: 3rem; height: 3rem;"
                  type="grow"
                  label="Spinning"
                ></b-spinner>-->
                <div
                  style="
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;
                    justify-content: center;
                    position: absolute;
                    z-index: 1029;
                    top: 6px;
                    left: 6px;
                    bottom: 6px;
                    right: 6px;
                  "
                >
                  <div>
                    <span class style="font-size: 24px !important">🤗</span>
                    <span
                      class="dotloading"
                      style="font-size: 54px !important"
                    ></span>
                  </div>
                  <span>I'm searching for any new appointments</span>
                  <span>Just a moment</span>
                </div>
              </div>
              <b-badge
                pill
                variant="light"
                class="my-2 py-1 text-info pills"
                style="
                  font-size: 10px;
                  font-family: sans-serif;
                  letter-spacing: 2px;
                "
                >COVID-19</b-badge
              >
              <h4 class="text-dark m-0">
                Your Coronavirus Testing
                <br />Starts Here
              </h4>
              <small class="text-muted"
                >Get tested in minutes. Book for your test now.</small
              >
              <div class="mt-2">
                <b-badge
                  pill
                  variant="light"
                  to="#"
                  v-b-modal="'corona-modal'"
                  class="my-2 p-3 text-info pills"
                  style="
                    font-family: sans-serif;
                    font-size: 15px;
                    line-height: 19px;
                  "
                  >Click here to Book a Covid-19 Test</b-badge
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row m-0 my-3">
        <div class="col-lg-6 col-md-6 p-2 col-12 mb-3">
          <div class="card shadow" style="border: 0px; border-radius: 24px">
            <div class="text-left p-3">
              <b-badge
                pill
                variant="light"
                class="my-2 py-1 text-info pills"
                style="
                  font-size: 10px;
                  font-family: sans-serif;
                  letter-spacing: 2px;
                "
                >YOUR STATUS</b-badge
              >
              <h4 class="text-dark m-0">
                You have
                {{ userSessionData.upcomingAppointments.length }} upcoming
                {{
                  userSessionData.upcomingAppointments.length > 1
                    ? "appointments"
                    : "appointment"
                }}
              </h4>
              <small
                v-if="userSessionData.unconfirmedAppointments.length > 0"
                class="text-muted"
              >
                You need to pay for
                <a :href="'/#' + unconfirmedAppointmentUrl">
                  {{ userSessionData.unconfirmedAppointments.length }}
                  appointment
                </a>
                too
              </small>
              <small v-else class="text-muted">{{
                "and you have no unconfirmed appointments"
              }}</small>
              <br />
              <!--<small class="text-muted"
                >and this appointment needs to be confirmed</small
              ><br />-->
              <small class="text-muted">
                before they are confirmed and become upcoming
                <!-- You can
                <a href="/#/appointments">view all your appointments</a>
                here-->
              </small>
              <div class="mt-2">
                <b-badge
                  v-if="
                    userSessionData.upcomingAppointments.length > 0 ||
                    userSessionData.unconfirmedAppointments.length > 0
                  "
                  pill
                  variant="light"
                  :to="url"
                  class="my-2 p-3 text-info pills"
                  style="
                    font-family: sans-serif;
                    font-size: 15px;
                    line-height: 19px;
                  "
                  >Click here to start your conversation</b-badge
                >
                <b-badge
                  v-else
                  pill
                  variant="light"
                  :to="url"
                  class="my-2 p-3 text-info pills"
                  style="
                    font-family: sans-serif;
                    font-size: 15px;
                    line-height: 19px;
                  "
                  >Click here to Book an Appoinment</b-badge
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center m-0">
        <div class="col-lg-6 col-md-6 p-2 col-12 mb-3">
          <router-link to="#" v-b-modal="'gp-modal'">
            <div
              class="card1 card shadow-sm"
              style="border: 0px; border-radius: 12px"
            >
              <div class="p-3">
                <div class="row m-0 align-items-center">
                  <div class="col-3 p-0 text-center">
                    <img
                      src="@/assets/doctor.svg"
                      alt
                      style="width: 100%; max-width: 64px"
                    />
                  </div>
                  <div class="col-9 text-left">
                    <div class="card-head">Consult a General Doctor</div>
                    <small class="text-muted"
                      >Engage a doctor in a video chat about your health
                      now</small
                    >
                    <!-- <p class="text-sm text-success"></p> -->
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-6 col-md-6 p-2 col-12 mb-3">
          <router-link to="#" v-b-modal="'specialist-modal'">
            <div
              class="card1 card shadow-sm"
              style="border: 0px; border-radius: 12px"
            >
              <div class="p-3">
                <div class="row m-0 align-items-center">
                  <div class="col-3 p-0 text-center">
                    <img
                      src="@/assets/neurosurgery.svg"
                      alt
                      style="width: 100%; max-width: 64px"
                    />
                  </div>
                  <div class="col-9 text-left">
                    <div class="card-head">
                      Consult with a Specialist Doctor
                    </div>
                    <small class="text-muted"
                      >Have a video chat with a specialist in any field</small
                    >
                    <!-- <p class="text-sm text-success"></p> -->
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <!--<div class="col-lg-6 col-md-6 p-2 col-12 mb-3">
        <router-link to="/appoint-bot">
          <div class="card1 card shadow-sm" style="border: 0px; border-radius: 12px">
            <div class="p-3">
              <div class="row m-0 align-items-center">
                <div class="col-3 p-0 text-center">
                  <img src="@/assets/sofa.svg" alt style="width: 100%; max-width: 64px;" />
                </div>
                <div class="col-9 text-left">
                  <div class="card-head">Home Virtual Care</div>
                  <small class="text-muted">Better understand your risk of the virus.</small>
                  <!- <p class="text-sm text-success"></p> ->
                </div>
              </div>
            </div>
          </div>
        </router-link>
        </div>-->

        <div class="col-lg-6 col-md-6 p-2 col-12 mb-3">
          <router-link to="#" v-b-modal="'corona-modal'">
            <div
              class="card1 card shadow-sm"
              style="border: 0px; border-radius: 12px"
            >
              <div class="p-3">
                <div class="row m-0 align-items-center">
                  <div class="col-3 p-0 text-center">
                    <img
                      src="@/assets/virus.svg"
                      alt
                      style="width: 100%; max-width: 64px"
                    />
                  </div>
                  <div class="col-9 text-left">
                    <div class="card-head">Book a Covid 19 Test</div>
                    <small class="text-muted">
                      Ideal for COVID-19 PCR Testing (No Serology Testing).
                    </small>
                    <!-- <p class="text-sm text-success"></p> -->
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-6 col-md-6 p-2 col-12 mb-3">
          <router-link to="/queue-bot">
            <div
              class="card1 card shadow-sm"
              style="border: 0px; border-radius: 12px"
            >
              <div class="p-3">
                <div class="row m-0 align-items-center">
                  <div class="col-3 p-0 text-center">
                    <img
                      src="@/assets/sofa.svg"
                      alt
                      style="width: 100%; max-width: 64px"
                    />
                  </div>
                  <div class="col-9 text-left">
                    <div class="card-head">
                      Know where you are in the Covid Test Queue
                    </div>
                    <small class="text-muted">
                      Know where you are in getting your covid-19 test done
                    </small>
                    <!-- <p class="text-sm text-success"></p>-->
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <!-- <div class="col-lg-6 col-md-6 p-2 col-12 mb-3">
          <a href="https://serenity.health/">
          <div class="card1 card shadow-sm" style="border: 0px; border-radius: 12px">
        <div class="p-3">
          <div class="row m-0 align-items-center">
            <div class="col-3 p-0 text-center">
              <img src="@/assets/sofa.svg" alt="" style="width: 100%; max-width: 64px;">
            </div>
            <div class="col-9 text-left">
              <div class="card-head">General Virtual Care</div>
            <small class="text-muted">Better understand your risk of the virus.</small>
            <p class="text-sm text-success"></p>
            </div>
          </div>
        </div>
      </div>
      </a>
        </div>-->
      </div>
      <div class="mt-5">
        <b
          class="text-left"
          style="
            font-size: 1.5rem;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            display: block;
          "
          >My Health</b
        >
        <div class="row m-0 align-items-center">
          <div class="col-lg-4 p-2 col-md-4 col-12 mb-3">
            <div
              class="ref-card card1 card shadow-sm"
              :class="[
                covidReady.result == 'positive' ? 'fail-test' : 'not-tested',
                covidReady.result == 'negative' ? 'success-test' : 'not-tested',
              ]"
              style="border: 0px; border-radius: 12px"
              @click="covidView"
            >
              <div class="p-3 p-md-2">
                <div class="row m-0 justify-content-between align-items-center">
                  <div class="col p-0 text-left">
                    <!-- <span class="fa fa-shield-virus fa-2x text-light"></span> -->
                    <img
                      src="@/assets/coronavirus.svg"
                      width="34px"
                      height="34px"
                      alt=""
                    />
                  </div>
                  <div>
                    <b-badge variant="light" pill class="text-secondary">{{
                      covidReady ? "Tested" : "Not tested"
                    }}</b-badge>
                  </div>
                  <!-- <div class="col text-right">
                    <span
                      class="bg-white arrow-container"
                      style="border-radius: 50%; cursor: pointer"
                      @click="covidReady"
                    >
                      <font-awesome-icon class="fa font-size-15" icon="angle-right" />
                      <i class="fa fa-angle-right"></i>
                    </span>
                  </div>-->
                </div>
                <div class="text-left pt-2">
                  <!-- <p class="text-sm text-success"></p> -->
                  <div class="card-head">Check Covid Ready status now!</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 p-2 col-12 mb-3">
            <div
              class="card1 card shadow-sm"
              style="border: 0px; border-radius: 12px"
              @click="goToAppointments"
            >
              <div class="p-3">
                <div class="row m-0 align-items-center">
                  <div class="col p-0 text-left">
                    <span class="lnr icon lnr-calendar-full text-info"></span>
                  </div>
                  <div class="col text-right">
                    <span class="count-box px-2 py-1" v-if="loggedIn">
                      {{ appointmentsCount }}
                    </span>
                    <span class="count-box px-2 py-1" v-else>0</span>
                  </div>
                </div>
                <div class="text-left pt-2">
                  <!-- <p class="text-sm text-success"></p> -->
                  <div class="card-head">Appointments</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 p-2 col-12 mb-3">
            <div
              class="card1 card shadow-sm"
              style="border: 0px; border-radius: 12px"
              @click="goToVitals"
            >
              <div class="p-3">
                <div class="row m-0 align-items-center">
                  <div class="col p-0 text-left">
                    <span class="lnr icon lnr-heart-pulse text-danger"></span>
                  </div>
                  <div class="col text-right">
                    <span class="count-box px-2 py-1" v-if="loggedIn">0</span>
                    <span class="count-box px-2 py-1" v-else>0</span>
                  </div>
                </div>
                <div class="text-left pt-2">
                  <div class="card-head">Vitals</div>
                  <!-- <p class="text-sm text-success"></p> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 p-2 col-12 mb-3">
            <div
              class="card1 card shadow-sm"
              style="border: 0px; border-radius: 12px"
              @click="goToPrescriptions"
            >
              <div class="p-3">
                <div class="row m-0 align-items-center">
                  <div class="col p-0 text-left">
                    <span
                      class="fa icon fa-prescription-bottle text-primary"
                    ></span>
                  </div>
                  <div class="col text-right">
                    <span class="count-box px-2 py-1" v-if="loggedIn">{{
                      prescriptionsCount
                    }}</span>
                    <span class="count-box px-2 py-1" v-else>0</span>
                  </div>
                </div>
                <div class="text-left pt-2">
                  <div class="card-head">Prescriptions</div>
                  <!-- <p class="text-sm text-success"></p> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 p-2 col-12 mb-3">
            <div
              class="card1 card shadow-sm"
              style="border: 0px; border-radius: 12px"
              @click="goToLabs"
            >
              <div class="p-3">
                <div class="row m-0 align-items-center">
                  <div class="col p-0 text-left">
                    <span class="fa icon fa-flask text-success"></span>
                  </div>
                  <div class="col text-right">
                    <span class="count-box px-2 py-1" v-if="loggedIn">{{
                      labsCount
                    }}</span>
                    <span class="count-box px-2 py-1" v-else>0</span>
                  </div>
                </div>
                <div class="text-left pt-2">
                  <div class="card-head">Labs</div>
                  <!-- <p class="text-sm text-success"></p> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 p-2 col-12 mb-3">
            <div
              class="card1 card shadow-sm"
              style="border: 0px; border-radius: 12px"
            >
              <div class="p-3">
                <div class="row m-0 align-items-center">
                  <div class="col p-0 text-left">
                    <span class="far icon fa-folder-open text-warning"></span>
                  </div>
                  <div class="col text-right">
                    <span class="count-box px-2 py-1">0</span>
                  </div>
                </div>
                <div class="text-left pt-2">
                  <div class="card-head">Documents</div>
                  <!-- <p class="text-sm text-success"></p> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 p-2 col-12 mb-3">
            <div
              class="card1 card shadow-sm"
              style="border: 0px; border-radius: 12px"
              @click="goToImaging"
            >
              <div class="p-3">
                <div class="row m-0 align-items-center">
                  <div class="col p-0 text-left">
                    <span class="fa icon fa-x-ray text-dark"></span>
                  </div>
                  <div class="col text-right">
                    <span class="count-box px-2 py-1" v-if="loggedIn">0</span>
                    <span class="count-box px-2 py-1" v-else>0</span>
                  </div>
                </div>
                <div class="text-left pt-2">
                  <div class="card-head">Medical Images</div>
                  <!-- <p class="text-sm text-success"></p> -->
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-4 col-md-4 p-2 col-12 mb-3">
          <a href="https://serenity.health/">
          <div class="card1 card shadow-sm" style="border: 0px; border-radius: 12px">
        <div class="p-3">
          <div class="row m-0 align-items-center">
            <div class="col p-0 text-left">
          <span class="lnr icon lnr-heart-pulse "></span>
            </div>
            <div class="col-9 text-left">
              <div class="card-head">Connected Devices</div>
            <p class="text-sm text-success"></p>
            </div>
          </div>
        </div>
      </div>
        </div
          </a>>-->
          <!--{{userInfo}}-->
          <div class="col-lg-4 col-md-4 p-2 col-12 mb-3">
            <div
              class="card1 card shadow-sm"
              style="border: 0px; border-radius: 12px"
              @click="goToMedicalHistory"
            >
              <div class="p-3">
                <div class="row m-0 align-items-center">
                  <div class="col p-0 text-left">
                    <span class="fa icon fa-history text-secondary"></span>
                  </div>
                  <div class="col text-right">
                    <!-- <span class="count-box px-2 py-1" v-if="loggedIn">0</span> -->
                    <span class="count-box px-2 py-1">0</span>
                  </div>
                </div>
                <div class="text-left pt-2">
                  <div class="card-head">Medical History</div>
                  <!-- <p class="text-sm text-success"></p> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 p-2 col-12 mb-3">
            <div
              class="card1 card shadow-sm"
              style="border: 0px; border-radius: 12px"
              @click="goToDependants"
            >
              <div class="p-3">
                <div class="row m-0 align-items-center">
                  <div class="col p-0 text-left">
                    <img
                      src="@/assets/insurance.svg"
                      height="24px"
                      width="24px"
                      alt=""
                    />
                  </div>
                  <div class="col text-right">
                    <!-- <span class="count-box px-2 py-1" v-if="loggedIn">0</span> -->
                    <span class="count-box px-2 py-1">0</span>
                  </div>
                </div>
                <div class="text-left pt-2">
                  <div class="card-head">Dependants</div>
                  <!-- <p class="text-sm text-success"></p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-4" style="margin-top: 30px !important">
        <img src="@/assets/sunrise.svg" alt="Nice day" />
        <h5 class="mt-3 text-muted">Have a nice day</h5>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import http from "@/network/Base.js";
import { mapState } from "vuex";

export default {
  name: "home",
  data() {
    return {
      loading: false,
      image: require("@/assets/logo.svg"),
      appointmentsCount: 0,
      prescriptionsCount: 0,
      labsCount: 0,
      selected: "labs",
      modalCorona: false,
      modalSpecial: false,
      modalGP: false,
      CoronaTiers: [],
      search: "",
      covidReady: "",
      specialLists: [],
      appointmentUrl: "",
      unconfirmedAppointmentUrl: "",
      userSessionData: {
        upcomingAppointments: [],
        unconfirmedAppointments: [],
      },
      options: [
        { text: "Check lab results", value: "labs" },
        { text: "Schedule an appointment", value: "appointment" },
        { text: "Track my vitals", value: "vitals" },
      ],
      lists: [
        {
          sz_fname: "Dr. Morgan Barnes",
          sz_wunit: "Podiatrist",
          sz_image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSLpPBjip3vZPE3gioCeA1wgr64gbZg5l0lAg&usqp=CAU",
          sz_time: "Tomorrow, 2:00pm",
        },
        {
          sz_fname: "Dr. Selina Darko",
          sz_wunit: "Pediatrician",
          sz_image:
            "https://cdn10.bostonmagazine.com/wp-content/uploads/sites/9/2019/06/Emily-Wise-838x1024.jpg",
          sz_time: "Tomorrow, 10:00am",
        },
        {
          sz_fname: "Dr. Pope Jones",
          sz_wunit: "Neurologist",
          sz_image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQXvSEGK1CbcDY5UA_Pm3YXvwz4gH8u62_ZIQ&usqp=CAU",
          sz_time: "Tomorrow, 6:00pm",
        },
        {
          sz_fname: "Dr. Bob Binitie",
          sz_wunit: "Psychiatrist",
          sz_image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSLpPBjip3vZPE3gioCeA1wgr64gbZg5l0lAg&usqp=CAU",
          sz_time: "Tomorrow, 3:30pm",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
      loggedIn: (state) => state.authStore.loggedIn,
    }),
    specialList: {
      get: function () {
        let search = this.search.trim().toLowerCase();

        return this.search.trim() !== ""
          ? this.specialLists
              .filter((d) => {
                return (
                  d.sz_fname.toString().toLowerCase().indexOf(search) > -1 ||
                  d.sz_lname.toString().toLowerCase().indexOf(search) > -1 ||
                  d.sz_oname.toString().toLowerCase().indexOf(search) > -1 ||
                  d.specialty.toString().toLowerCase().indexOf(search) > -1
                  //  ||
                  // d.sz_lname.toString().toLowerCase().indexOf(search) > -1
                );
              })
              .slice(0, 6)
          : this.specialLists.slice(0, 6);
      },
      // setter
      set: function (newValue) {
        this.specialLists = newValue;
      },
    },
    getGreeting() {
      const greeting = [
        "Ola",
        "Hello",
        "Hi",
        "Welcome",
        "Howdy",
        "Good to see you",
      ];
      return greeting[Math.floor(Math.random() * greeting.length)];
    },
    url() {
      return this.appointmentUrl;
    },
    getTimeOfDay() {
      const hr = new Date().getHours();
      if (hr < 12) return "Good Morning";
      else if (hr < 17) return "Good Afternoon";
      else return "Good Evening";
    },
  },
  methods: {
    openCorona(name) {
      this.$router.push({ path: "/corona-tool" });
      this.$store.commit("serviceStore/setSelect", name);
    },
    openSpecialist(name) {
      this.$router.push({ path: "/specialist-appoint-bot" });
      this.$store.commit("serviceStore/setSelect", name);
    },
    close() {
      this.$bvModal.hide("gp-modal");
    },
    eligible() {
      this.$router.push({ path: "/appoint-bot" });
    },
    covidView() {
      this.$router.push({ path: "/covid-ready" });
      this.$store.commit("serviceStore/setCovid", this.covidReady);
    },
    goToAppointments() {
      this.$router.push({ name: "appointments-route" });
    },
    goToVitals() {
      this.$router.push({ name: "vitals-route" });
    },
    goToPrescriptions() {
      this.$router.push({ name: "prescriptions-route" });
    },
    goToLabs() {
      this.$router.push({ name: "labs-route" });
    },
    goToImaging() {
      this.$router.push({ name: "imagings-route" });
    },
    goToMedicalHistory() {
      this.$router.push({ name: "development-route" });
    },
    goToDependants() {
      this.$router.push({ name: "dependants-route" });
    },
    getVisits() {
      this.loading = true;
      http()
        .get(
          `/patients/appointments?id=&sz_bookingstatus=&sz_docempid=&service=&clinicId=&expiredStatus=&sz_bookedforid=${this.userInfo.szpatientid}`
        )
        .then(({ data }) => {
          this.appointmentsCount = data.count;
          data.results.map((appointment) => {
            if (
              !appointment.expiredStatus &&
              appointment.sz_bookingstatus === "Confirmed"
            )
              this.userSessionData.upcomingAppointments.push(appointment);
            if (
              !appointment.expiredStatus &&
              appointment.sz_bookingstatus === "New"
            )
              this.userSessionData.unconfirmedAppointments.push(appointment);
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          // this.$snotify.error('Sorry, cannot get Service detail')
        })
        .finally(() => {
          if (this.userSessionData.upcomingAppointments.length > 0) {
            if (this.userSessionData.upcomingAppointments.length > 1)
              this.appointmentUrl = "/appointments/upcoming";
            else
              this.appointmentUrl = `/appointments/${this.userSessionData.upcomingAppointments[0].id}`;
          } else {
            if (this.userSessionData.unconfirmedAppointments.length > 1) {
              this.appointmentUrl = `/appointments/unconfirmed`;
            } else if (
              this.userSessionData.unconfirmedAppointments.length === 1
            ) {
              this.appointmentUrl = `/appointments/${this.userSessionData.unconfirmedAppointments[0].id}`;
            } else {
              // navigate to appointment booking
              this.appointmentUrl = `/appoint-bot/all-services`;
            }
          }
          if (this.userSessionData.unconfirmedAppointments.length > 1)
            this.unconfirmedAppointmentUrl = "/appointments/unconfirmed";
          else if (this.userSessionData.unconfirmedAppointments.length > 0)
            this.unconfirmedAppointmentUrl = `/appointments/${this.userSessionData.unconfirmedAppointments[0].id}`;
          this.loading = false;
        });
    },
    getPrescriptions() {
      this.loading = true;
      http()
        .get(
          `/encounter/medicationrequest?patientId=${this.userInfo.szpatientid}`
        )
        .then(({ data }) => {
          this.prescriptionsCount = data.length;
        })
        .catch((error) => {
          console.log(error);
          // this.$snotify.error('Sorry, cannot get Service detail')
        })
        .finally(() => {});
    },
    getSpecialists() {
      this.loading = true;
      http()
        .get(`/employees?specialty=`)
        .then(({ data }) => {
          this.specialLists = data;
        })
        .catch((error) => {
          console.log(error);
          // this.$snotify.error('Sorry, cannot get Service detail')
        })
        .finally(() => {});
    },
    getLabs() {
      this.loading = true;
      http()
        .get(`/visits/diagnosticreport?patientId=${this.userInfo.szpatientid}`)
        .then(({ data }) => {
          this.labsCount = data.count;
          let result = data.results.reverse();
          let index = 0;
          result.forEach((element) => {
            if (element.code === "SARS-COV-2" && index === 0) {
              let status = JSON.parse(element.results);
              element.result = status[0].text || "";
              this.covidReady = element;
              index++;
              console.log(this.covidReady);
            }
          });
        })
        .catch((error) => {
          console.log(error);
          // this.$snotify.error('Sorry, cannot get Service detail')
        })
        .finally(() => {});
    },
  },
  created() {
    if (this.$route.query["covid"]) {
      this.modalCorona = true;
    }
    if (this.$route.query["special"]) {
      this.modalSpecial = true;
    }
    this.CoronaTiers = this.$store.state.serviceStore.listCV;
    this.specialLists = this.$store.state.serviceStore.specialties;
    this.getVisits();
    this.getPrescriptions();
    this.getLabs();
  },
};
</script>

<style lang="scss" scoped>
.report-card {
  position: relative;
  overflow: hidden;
}
.report-card img {
  position: absolute;
  z-index: 2;
  opacity: 0.3;
  top: -57%;
  right: 5%;
}
/* Radio Button */
input[type="radio"] {
  display: none;
}

input[type="radio"] + .form-check-label {
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 24px;
  background-color: white;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #787878;
  color: #787878;
}

// input[type="radio"] + label {
// }

input[type="radio"]:hover + label,
input[type="radio"]:checked + label,
input[type="radio"]:focus + label {
  cursor: pointer;
  background-color: rgb(0, 172, 164);

  border: 0px;
  color: #ffffff;
}
.pills {
  background-color: rgba(187, 255, 228, 0.721);
}
.ref-contain {
  // margin: 20px;
  padding: 10px;
}
.ref-card {
  // background: #e6e6e6 0% 0% no-repeat padding-box;
  border-radius: 14px;
  .arrow-container {
    padding: 8px 12px;
  }
}
.search-control {
  padding-left: 2.375rem !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 0.6875rem;
  letter-spacing: 0px;
  padding: 0px 0.8125rem;
  height: 2.875rem;
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
  width: 100%;
  border: 1px solid gainsboro;
  font-size: 1rem;
  text-align: left;
  color: #000000;
  box-shadow: none !important;
  outline: none;

  &:focus {
    border-color: lighten(#2a94f6, 40%) !important;
    background: #eff2f5 0% 0% no-repeat padding-box;
    border: 1px solid #2a94f6 !important;
  }

  &.--adorned {
    border: none !important;
    box-shadow: none;
  }

  &.--border-none {
    border: none !important;
  }

  &::placeholder {
    color: #a8a8a8;
  }
}
.has-search {
  position: relative;
}

.form-control-feedback {
  position: absolute;
  z-index: 2;
  margin-top: auto;
  bottom: 1.82rem;
  left: 0.8rem;
  font-size: 18px;
  pointer-events: none;
  color: #aaa;
}

.not-tested {
  background: #e6e6e6 0% 0% no-repeat padding-box;
}
.success-test {
  background: #97ff97a6 0% 0% no-repeat padding-box;
}

.fail-test {
  background: #ff9797a6 0% 0% no-repeat padding-box;
}

.greeting {
  vertical-align: baseline;
  letter-spacing: normal;
  font-weight: 500;
}
.card1 {
  cursor: pointer;
}
.card1:hover {
  background-color: rgb(243, 243, 243);
}
.icon {
  font-size: 24px;
}
.card-head {
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 500;
}
.count-box {
  background-color: #eee;
  border-radius: 50%;
  width: 2rem;
}
/* loading dots */

.dotloading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: black;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
}
.shadow {
  -webkit-box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.15) !important;
}
</style>
